<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">{{ this.$route.meta.label }} 
        <template v-if="isEdit">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
        <template v-else>
          Into <h3 class="d-inline"><CBadge color="secondary">{{ this.menuGroupName | uppercase }}</CBadge></h3>
        </template>
      </h5>
      <div class="card-header-actions">
        <!-- <CButton color="dark" :to="{ name: 'Menu Group Childs'}"> -->
        <CButton color="dark" @click="goBack">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <CForm>
        <CRow>
          <CCol col="12">
            <CInput
              label="Name"
              placeholder="Name"
              :lazy="false"
              :value.sync="$v.form.Name.$model"
              :isValid="checkIfValid('Name')"
              invalidFeedback="This is a required field and must be at least 2 characters"
            />
          </CCol>

          <CCol col="12">
            <CInput
              label="Price"
              placeholder="0.00"
              :lazy="false"
              :value.sync="$v.form.Price.$model"
              :isValid="checkIfValid('Price')"
              invalidFeedback="This is a required field and must be numeric"
              type="number"
              step="0.01" 
              pattern="^\d+(?:\.\d{1,2})?$"
            >
            <template #prepend-content><CIcon name="cil-euro"/></template>
            </CInput>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>

    <CCardFooter>
      <CButton 
        color="primary" 
        :disabled="submitted"
        @click="isEdit ? update() : store()"
      >
        Submit
      </CButton>
    </CCardFooter>

    <CElementCover :opacity="0.4" v-show="submitted"/>

  </CCard>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"

export default {
  name: 'MenuGroupItem',

  data() {
    return {
      form: {},
      isEdit: false,
      submitted: false,

      itemName: '',
      menuGroupName: "",

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    }
  },

  created () {
    if(this.itemId) {
      this.form = this.getForm(this.itemId)
      this.isEdit = true
    }
    else
      this.form = this.getForm()
  },

  mounted() {
    this.getMenuGroupName();
  },

  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    menuGroupId() {
      return this.$route.params.mnuExtGrpId;
    },
    itemId() {
      return this.$route.params.id
    }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      Name: {
        required,
        minLength: minLength(2)
      },
      Price: {
        required,
      },
    },
  },
  
  methods: {
    getMenuGroupName() {
      this.$axios
        .get(this.$backend.MENU_GROUPS.SHOW.replace("{id}", this.menuGroupId))
        .then((response) => {
          this.menuGroupName = response.data.Name;
        })
        .catch((error) => { this.menuGroupName = 'Not Found' })
    },

    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field.$model === '')
    },

    store () {
      if (this.isValid) {
        this.submitted = true
        // -(1)- Form Bilgilerini Kaydetme
        this.$axios.post(this.$backend.MENU_GROUP_CHILDS.STORE, this.form)
          .then((response)=>{
            this.successAlertMessage = {itemName: response.data.Name, message: 'menu group child added.'}
            this.dismissSuccessAlert = this.dismissSecs
            this.reset();
          })
          .catch((error)=>{
            this.errorAlertMessage = error.response.data
            this.showErrorAlert = true
            this.submitted = false
          })
      } // -(1)- Form Bilgilerini Kaydetme / end
      else
       this.validate()
    },

    update () {
      if (this.isValid) {
        this.submitted = true
        // -(1)- Form Bilgilerini Güncelleme
        this.$axios.put(this.$backend.MENU_GROUP_CHILDS.UPDATE.replace("{id}", this.itemId), this.form)
          .then((response)=>{
            this.itemName = response.data.Name
            this.successAlertMessage = {itemName: this.itemName, message: 'menu group child updated.'}
            this.dismissSuccessAlert = this.dismissSecs
            this.showErrorAlert = false
          })
          .catch((error)=>{
            this.errorAlertMessage = error.response.data
            this.showErrorAlert = true
          })
          .finally(() => {
            this.submitted = false
          });
      } // -(1)- Form Bilgilerini Güncelleme / end
      else
       this.validate()
    },

    validate () {
      this.$v.$touch()
    },

    reset () {
      this.form = this.getForm()
      this.submitted = false
      this.showErrorAlert = false
      this.$v.$reset()
    },

    getForm (id = null) {
      var form = { Name: "", Price: '', mnuExtGrpId: this.menuGroupId }

      if(id) {
        this.$axios
          .get(
            this.$backend.MENU_GROUP_CHILDS.SHOW.replace("{mnuExtGrpId}", this.menuGroupId).replace("{id}", id)
          )
          .then((res) => {
            form.Name =  res.data.Name;
            form.Price =  res.data.Price;
            this.itemName = res.data.Name;
          })
          .catch((error) => {
            this.$router.push({ name: 'Menu Group Childs' })
          })
      }
      return form;
    },

    goBack() {
      // this.user ? this.$router.go(-1) : this.$router.push({ name: 'Menu Group Childs' })
      // this.$router.go(-1)
      this.$router.push({ name: 'Menu Group Childs' })
    }
  }
}
</script>